// Variable
$font-family-sans-serif: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-family-monospace: 'Times New Roman', Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.875rem;
$font-size-normal: $font-size-base * 1;
$font-size-lg: $font-size-base * 1.25;
$font-size-md: $font-size-base * 1.125;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base;
$font-size-xl: $font-size-base * 1.35;
$font-size-icon-nav: $font-size-base * 1.5;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$sidebar-size: 237px;
$sidebar-mini-size: 56px;
$primary: #222d32;
$black: #000000;
$white: #ffffff;
$heather: #b8c7ce;
$gray-light: #d3d3d3;
$tetradic: #855c1f;
$torea: #2b3272;
$blue: #3461ff;
$periwinkle: #c3c6dd;
$whisper: #dfe8f2;
$endeavour: #92accb;
$dark-blue: #0f4c75;
$summner-sky: #40a9ff;
$sunset-orange: #ff4d4f;
$bg-color: #ecf0f5;
$midnight-blue: #c3c6dd;
$stroke: #d7d7d7;
$stoke-grey: #c3c6dd;
$color-active: #94abff;
$grey1: #c4c4c4;
$blue-link: #1041f1;
$triplet: #109cf1;
$mr-1: 13px;
$mr-icon: 6px;
$grey2: #929292;
.colorBlack {
  color: $black !important;
}

.colorYellow {
  color: #ff9c06 !important;
}

.colorGreen {
  color: #3cc13b !important;
}

.colorRed {
  color: #ff4949 !important;
}

.colorGrey2 {
  color: $grey2 !important;
}

// background
.bgYellow {
  background: #ff9c06 !important;
}

.bgGreen {
  background: #3cc13b !important;
}

.bgRed {
  background: #ff4949 !important;
}

.bgBlue {
  background: $triplet !important;
}
